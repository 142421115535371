import React, { useContext, useEffect, useRef, useState } from "react";
import Input from "../../ui/Input/index";
import { CiSearch } from "react-icons/ci";
import Button from "../Buttons/Button";
import CartIcon from "../../svgs/CartIcon";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  ABOUT_US_PREFIX_PATH,
  ACCOUNTS_PREFIX_PATH,
  CART_PREFIX_PATH,
  CONTACT_US_PREFIX_PATH,
  FAQS_PREFIX_PATH,
  INVOICES_PREFIX_PATH,
  PRODUCT_DETAILS_PREFIX_PATH,
  PRODUCTS_PREFIX_PATH,
  QUOTE_PREFIX_PATH,
  ROOT,
} from "../../../constants/route.constant";
import Dropdown from "../../ui/Dropdown";
import SignOut from "../../svgs/SignOut";
import About from "../../svgs/About";
import Invoice from "../../svgs/Invoice";
import Account from "../../svgs/Account";
import ContactUs from "../../svgs/ContactUs";
import FAQs from "../../svgs/FAQs";
import Dialog from "../Dialog";
import Radio from "../Radio";
import Login from "../../../views/auth/login";
import ForgotPassword from "../../../views/auth/forgotPassword";
import OTPInput from "../../../views/auth/otpInput";
import NewPassword from "../../../views/auth/newPassword";
import { useSelector } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import roleContext from "../../../context/roleContext";
import { getAllProducts } from "../../../services/commonService";
import Spinner from "../Spinner";
import {
  addToCartApi,
  deleteFromCart,
  getCartData,
  updateCartData,
} from "../../../services/cartService";
import Cookies from "js-cookie";
import { WEBSITE_URL } from "../../../constants/api.constant";
import { FiMinus, FiPlus } from "react-icons/fi";
import { FaRegUserCircle } from "react-icons/fa";
import { SuccessToast } from "../../../utils/commonFunctions";

const data = [
  {
    icon: <About />,
    title: `About ${process.env.REACT_APP_PROJECT_NAME} Service`,
    link: `${ABOUT_US_PREFIX_PATH}`,
  },
  { icon: <Invoice />, title: "Invoices", link: `${INVOICES_PREFIX_PATH}` },
  { icon: <Account />, title: "Account", link: `${ACCOUNTS_PREFIX_PATH}` },
  {
    icon: <ContactUs />,
    title: "Contact Us",
    link: `${CONTACT_US_PREFIX_PATH}`,
  },
  { icon: <FAQs />, title: "FAQ’s", link: `${FAQS_PREFIX_PATH}` },
];

const Header = () => {
  const navigate = useNavigate();
  const context = useContext(roleContext);
  const userLoggedIn = useSelector((state) => state?.auth?.session?.signedIn);
  const user = useSelector((state) => state?.auth?.user);
  const token = useSelector((state) => state?.auth?.session?.token);

  const userId = user?.id;
  const cartItems = useSelector(
    (state) => state?.cart?.cart?.carts[userId || "guest"] || []
  );
  const cartCountUser = context?.cartCount; // eslint-disable-next-line
  const cartTotalPrice = context?.totalPrice;
  const cartCount = cartItems?.length;
  const location = useLocation();
  const { signOut } = useAuth();
  const [loadingProductId, setLoadingProductId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [signInOpen, setSignInOpen] = useState(false);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [otpOpen, setOtpOpen] = useState(false);
  const [newPasswordOpen, setNewPasswordOpen] = useState(false);
  const [status, setStatus] = useState("wholesale");
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [updatedProducts, setUpdatedProducts] = useState([]);
  const [impersonateId, setImpersonateId] = useState();
  const searchRef = useRef(null);
  const searchRef1 = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const dynamicId = Cookies.get("dynamicId");
      const referrer = document.referrer;
      if (dynamicId && referrer) setImpersonateId(dynamicId);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchTerm.length >= 2) setDebouncedSearch(searchTerm);
      else setProducts([]);
    }, 200);

    return () => clearTimeout(handler);
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedSearch.length >= 2) fetchData(debouncedSearch); // eslint-disable-next-line
  }, [debouncedSearch]);

  const fetchData = async (query) => {
    try {
      setLoading(true);
      let payload = { search: query };
      if (userId) payload.customerCode = user?.customerCode;

      const response = await getAllProducts(10, 1, payload);
      if (response?.success) setProducts(response?.data || []);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCartData = async () => {
    try {
      const raw = { userId: user?.id, customerCode: user?.customerCode };
      const resp = await getCartData(raw);
      if (resp?.success) setCartData(resp?.data);
    } catch (err) {
      console.error("Failed to fetch cart data", err);
    }
  };

  // useEffect(() => {
  //   if (token && user?.id && user?.customerCode) {
  //     fetchCartData();
  //   }
  //   // eslint-disable-next-line
  // }, [token, user?.id, user?.customerCode]);

  useEffect(() => {
    if (token && user?.id && user?.customerCode) {
      const timer = setTimeout(() => {
        fetchCartData();
      }, 500);

      return () => clearTimeout(timer); // Cleanup timeout on unmount or dependency change
    }
    // eslint-disable-next-line
  }, [token, user?.id, user?.customerCode]);

  const handleSignOut = () => setDialogOpen(true);
  const closeDialog = () => setDialogOpen(false);
  // const openLoginDialog = () => setLoginOpen(true);
  const closeLoginDialog = () => setLoginOpen(false);
  const openSignInDialog = () => {
    setLoginOpen(false);
    setSignInOpen(true);
  };
  const closeSignInDialog = () => setSignInOpen(false);
  const openForgotPasswordDialog = () => {
    setSignInOpen(false);
    setForgotPasswordOpen(true);
  };
  const closeForgotPasswordDialog = () => setForgotPasswordOpen(false);
  const openOtpDialog = () => {
    setForgotPasswordOpen(false);
    setOtpOpen(true);
  };
  const closeOtpDialog = () => setOtpOpen(false);
  const openNewPasswordDialog = () => {
    setOtpOpen(false);
    setNewPasswordOpen(true);
  };
  const closeNewPasswordDialog = () => setNewPasswordOpen(false);
  const initial = user?.email?.charAt(0).toUpperCase();

  const handleCardClick = (product) => {
    setSearchTerm("");
    setDebouncedSearch("");
    navigate(`${PRODUCT_DETAILS_PREFIX_PATH}/${product?.productId}`, {
      state: { product },
    });
  };

  useEffect(() => {
    if (products?.length > 0 && cartData?.length > 0) {
      const mergedProducts = products?.map((product) => {
        const cartItem = cartData?.find(
          (item) => item?.productId === product?.productId
        );
        return {
          ...product,
          quantity: cartItem ? cartItem?.quantity : 0,
          userCartId: cartItem?.userCartId ?? null,
        };
      });
      setUpdatedProducts(mergedProducts);
    } else
      setUpdatedProducts(
        products?.map((product) => ({
          ...product,
          quantity: 0,
          userCartId: null,
        }))
      );
  }, [products, cartData]);

  const handleStopImpersonate = async () => {
    try {
      userLoggedIn && signOut();
      window.location.href = `${WEBSITE_URL}users`;
    } catch (err) {
      console.error("Failed to stop impersonation", err);
    }
  };
  const onSaveAddToCart = async (e, productId) => {
    e.stopPropagation();
    setLoadingProductId(productId);
    try {
      let raw = {
        userId: user?.id,
        productId,
        quantity: 1,
        customerCode: user?.customerCode,
      };
      const resp = await addToCartApi([raw]);
      if (resp?.success) {
        SuccessToast("Product Added to cart");
        setUpdatedProducts((prevProducts) =>
          prevProducts?.map((p) =>
            p.productId === productId
              ? { ...p, quantity: 1, userCartId: resp?.data?.[0]?.userCartId }
              : p
          )
        );
        await context.getCartCount();
      }
    } catch (err) {
      console.error("onSaveAddToCart err", err);
    } finally {
      setLoadingProductId(null);
    }
  };
  const updateQuantityCart = async (newQuantity, product) => {
    // console.log("product", product);
    try {
      const response = await updateCartData(product?.userCartId, {
        quantity: newQuantity,
      });
      if (response?.success) {
        SuccessToast("Quantity updated successfully");
        setUpdatedProducts((prevProducts) =>
          prevProducts?.map((p) =>
            p.productId === product?.productId
              ? { ...p, quantity: newQuantity }
              : p
          )
        );
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
    }
  };
  const handleCartIncrement = async (e, product) => {
    e.stopPropagation();
    const newQuantity = product.quantity + 1;
    await updateQuantityCart(newQuantity, product);
    await context.getCartCount();
  };
  const handleCartDecrement = async (e, product) => {
    e.stopPropagation();

    if (product.quantity === 1) {
      const raw = {
        userCartIds: [product.userCartId],
      };
      const resp = await deleteFromCart({ data: raw });
      if (resp?.success) {
        SuccessToast("Product removed from cart");
        setUpdatedProducts((prevProducts) =>
          prevProducts?.map((p) =>
            p.productId === product?.productId
              ? {
                  ...p,
                  quantity: 0,
                  userCartId: null,
                }
              : p
          )
        );
        await context.getCartCount();
      }
    } else {
      const newQuantity = product.quantity - 1;
      await updateQuantityCart(newQuantity, product);
      await context.getCartCount();
    }
  };
  const handleViewAll = () => {
    setSearchTerm("");
    setDebouncedSearch("");
    navigate(`${PRODUCTS_PREFIX_PATH}`, {
      state: { searchTerm: searchTerm },
      replace: true,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target) &&
        searchRef1.current &&
        !searchRef1.current.contains(event.target)
      ) {
        setSearchTerm("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full z-50">
      <div className="container mx-auto mt-[20px]">
        <div className="sm:hidden">
          <div className="flex items-center justify-between gap-6 lg:gap-52">
            <img
              className="cursor-pointer w-24 object-cover"
              src="/img/logo/logo.png"
              alt="logo"
              onClick={() => navigate(`${ROOT}`)}
            />

            <div
              className="flex items-center gap-6"
              onClick={() => {
                setSearchTerm("");
                setDebouncedSearch("");
              }}
            >
              <NavLink
                to={`${
                  userId ? `${CART_PREFIX_PATH}` : `${QUOTE_PREFIX_PATH}`
                }`}
                className="relative"
              >
                <CartIcon
                  color={
                    location?.pathname === "/cart" ||
                    location?.pathname === "/quote"
                      ? "#212d65"
                      : "#899CBB"
                  }
                />
                {userId ? (
                  <>
                    {cartCountUser > 0 && (
                      <span className="absolute -top-2 -right-2 bg-primary text-white text-xs font-semibold rounded-full w-4 h-4 flex items-center justify-center">
                        {cartCountUser}
                      </span>
                    )}
                    {/* {cartTotalPrice > 0 && (
                      <span className="absolute -bottom-6 p-1 rounded bg-primary text-white text-xs font-semibold flex items-center justify-center">
                        <span className="flex"> {cartTotalPrice}</span>
                      </span>
                    )} */}
                  </>
                ) : (
                  <>
                    {cartCount > 0 && (
                      <span className="absolute -top-2 -right-2 bg-primary text-white text-xs font-semibold rounded-full w-4 h-4 flex items-center justify-center">
                        {cartCount}
                      </span>
                    )}
                  </>
                )}
              </NavLink>
              {userLoggedIn ? (
                <Dropdown
                  placement="bottom-end"
                  title={
                    <div className="w-[38px] h-[38px] rounded-full cursor-pointer bg-primary flex items-center justify-center text-white font-bold text-base">
                      {initial}
                    </div>
                  }
                >
                  <div className="w-[300px] max-h-[360px] min-h-[280px]">
                    <div className="">
                      <div className="px-4 py-2 hover:bg-[#F6FAFF] flex items-center gap-3 cursor-pointer border-b border-primary border-opacity-40">
                        <span className="w-[28px] h-[28px] border border-primary rounded-[4px] flex items-center justify-center">
                          <FaRegUserCircle className="text-primary" />
                        </span>
                        <div className="text-primary font-semibold">
                          {user?.userName}
                        </div>
                      </div>
                      {data?.map((item, index) => (
                        <NavLink
                          key={index}
                          to={item?.link}
                          className="px-4 py-2 hover:bg-[#F6FAFF] flex items-center gap-3"
                        >
                          <Dropdown.Item eventKey="a" className="!h-auto !p-0">
                            <span className="w-[28px] h-[28px] border border-primary rounded-[4px] flex items-center justify-center">
                              {item?.icon}
                            </span>
                            <span className="text-primary text-base font-semibold">
                              {item?.title}
                            </span>
                          </Dropdown.Item>
                        </NavLink>
                      ))}
                    </div>
                    <div className="mx-3 my-2">
                      <Dropdown.Item eventKey="a" className="!h-auto !p-0">
                        {impersonateId ? (
                          <Button
                            className="w-full rounded-lg text-base font-semibold"
                            type="submit"
                            icon={<SignOut />}
                            onClick={handleStopImpersonate}
                          >
                            Exit Impersonation
                          </Button>
                        ) : (
                          <Button
                            className="w-full rounded-lg text-sm font-semibold"
                            type="submit"
                            icon={<SignOut />}
                            onClick={handleSignOut}
                          >
                            Sign out
                          </Button>
                        )}
                      </Dropdown.Item>
                    </div>
                  </div>
                </Dropdown>
              ) : (
                <Button
                  variant="plain"
                  onClick={openSignInDialog}
                  className="rounded-lg border-[#474747] text-[#222222] font-semibold"
                >
                  Login
                </Button>
              )}
            </div>
          </div>
          <div className="w-full mt-3 relative" ref={searchRef1}>
            <Input
              placeholder="Search for Products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              prefix={
                <CiSearch
                  size={20}
                  className="text-[#899CBB] font-semibold text-base"
                />
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") handleViewAll();
              }}
            />
            {searchTerm?.length >= 2 && (
              <>
                <ul className="absolute left-0 mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg max-h-[500px] overflow-auto z-10">
                  <div className="h-[50px] bg-[#F6FAFF] shadow-sm p-4 text-[#222222] font-semibold text-base">
                    Showing Results For {searchTerm}
                  </div>
                  {loading ? (
                    <div className="flex items-center justify-center h-[350px]">
                      <Spinner loading={true} size={32} />
                    </div>
                  ) : (userId ? updatedProducts : products)?.length > 0 ? (
                    <>
                      <div className="flex-1 overflow-auto max-h-[350px]">
                        {(userId ? updatedProducts : products)?.map(
                          (product) => {
                            // console.log("product sdfsdf :>> ", product);
                            return (
                              <li
                                key={product.id}
                                className="p-4 cursor-pointer hover:bg-[#F6FAFF] hover:rounded-xl transition"
                                onClick={() => handleCardClick(product)}
                              >
                                <div className="grid lg:grid-cols-4 gap-2">
                                  <div className="flex items-center gap-2 col-span-2">
                                    <img
                                      className="w-8 h-8 rounded object-cover"
                                      src={
                                        product?.image ||
                                        "/img/home/productImg.png"
                                      }
                                      alt="product"
                                    />
                                    <div>
                                      <div className="text-sm">
                                        {product?.description
                                          ?.split("#")[0] // Take only the part before the first "#"
                                          ?.split(" ") // Split by spaces
                                          ?.slice(0, 10) // Take the first 10 words
                                          ?.join(" ") + // Join back into a string
                                          (product?.description
                                            ?.split("#")[0]
                                            ?.split(" ")?.length > 10
                                            ? "..."
                                            : "")}
                                      </div>
                                      <div className="text-sm">
                                        {product?.productCode}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex gap-3 items-center">
                                    <div className="text-sm font-extrabold  text-[#899CBB]">
                                      {product?.uomCode}
                                    </div>
                                    {userId ? (
                                      <div className="border-l text-sm font-extrabold  text-[#899CBB]">
                                        {/* {product?.specialPrice?.NewPrice
                                          ? (product?.uomMultiplier
                                              ? product?.specialPrice
                                                  ?.NewPrice *
                                                product?.uomMultiplier
                                              : product?.specialPrice?.NewPrice
                                            )?.toFixed(2)
                                          : (product?.uomMultiplier
                                              ? product?.price *
                                                product?.uomMultiplier
                                              : product?.price
                                            )?.toFixed(2)} */}
                                        {(product?.specialPrice?.NewPrice
                                          ? product?.specialPrice?.NewPrice
                                          : product?.uomMultiplier
                                          ? product?.price *
                                            product?.uomMultiplier
                                          : product?.price
                                        )?.toFixed(2)}
                                        {/* {product?.specialPrice?.NewPrice
                                          ? product?.specialPrice?.NewPrice?.toFixed(
                                              2
                                            )
                                          : product?.price} */}
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                  </div>
                                  <div className="">
                                    {userId ? (
                                      <>
                                        {product?.quantity === 0 ? (
                                          <Button
                                            size="sm"
                                            onClick={(e) => {
                                              onSaveAddToCart(
                                                e,
                                                product?.productId
                                              );
                                            }}
                                            disabled={
                                              loadingProductId ===
                                              product?.productId
                                            }
                                            loading={
                                              loadingProductId ===
                                              product?.productId
                                            }
                                            className="mt-4 text-xs md:text-base !w-[120px] rounded-lg font-semibold"
                                          >
                                            Add
                                          </Button>
                                        ) : (
                                          <div className="mt-4 border border-primary !w-[120px] rounded-lg px-2 py-2 flex items-center justify-between">
                                            <button
                                              onClick={(e) =>
                                                handleCartDecrement(e, product)
                                              }
                                              className="p-1 focus:outline-none"
                                              aria-label="Decrement"
                                            >
                                              <FiMinus className="text-primary" />
                                            </button>
                                            <span className="text-sm md:text-base font-semibold text-[#899CBB]">
                                              {product?.quantity}
                                            </span>
                                            <button
                                              onClick={(e) =>
                                                handleCartIncrement(e, product)
                                              }
                                              className="p-1 focus:outline-none"
                                              aria-label="Increment"
                                            >
                                              <FiPlus className="text-primary" />
                                            </button>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <Button
                                        size="sm"
                                        onClick={() => {
                                          setSearchTerm("");
                                          setSignInOpen(true);
                                        }}
                                        className="mt-4 text-xs md:text-sm !w-[120px] rounded-lg font-semibold"
                                      >
                                        Add
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </li>
                            );
                          }
                        )}
                      </div>
                      <div className="sticky bottom-0 bg-white border-t border-gray-300 p-2 flex justify-center">
                        <Button
                          size="sm"
                          variant="plain"
                          className="text-sm md:text-base rounded-lg font-semibold"
                          onClick={() => {
                            handleViewAll();
                          }}
                        >
                          View All
                        </Button>
                      </div>
                    </>
                  ) : (
                    <li className="p-2 text-[#222222] text-center">
                      Sorry! Couldn't Find this product
                    </li>
                  )}
                </ul>
              </>
            )}
          </div>
        </div>
        <div className="hidden sm:flex items-center justify-between gap-12 lg:gap-36">
          <img
            className="cursor-pointer"
            src="/img/logo/logo.png"
            alt="logo"
            onClick={() => {
              setSearchTerm("");
              setDebouncedSearch("");
              navigate(`${ROOT}`);
            }}
          />
          <div className="w-full relative" ref={searchRef}>
            <Input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search for Products..."
              prefix={
                <CiSearch
                  size={20}
                  className="text-[#899CBB] font-semibold text-base"
                />
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") handleViewAll();
              }}
            />
            {searchTerm.length >= 2 && (
              <>
                <ul className="absolute left-0 mt-2 w-full bg-white border border-gray-300 rounded-lg shadow-lg max-h-[500px] flex flex-col z-10">
                  <div className="h-[50px] bg-[#F6FAFF] shadow-sm p-4 text-[#222222] font-semibold text-sm">
                    Showing Results For {searchTerm}
                  </div>

                  {loading ? (
                    <div className="flex items-center justify-center h-[350px]">
                      <Spinner loading={true} size={32} />
                    </div>
                  ) : (userId ? updatedProducts : products)?.length > 0 ? (
                    <>
                      <div className="flex-1 overflow-auto max-h-[350px]">
                        {(userId ? updatedProducts : products)?.map(
                          (product) => (
                            <li
                              key={product.id}
                              className="px-4 py-3 cursor-pointer hover:bg-[#F6FAFF] hover:rounded-xl transition"
                              onClick={() => handleCardClick(product)}
                            >
                              <div className="grid grid-cols-4 gap-2">
                                <div className="flex items-center gap-2 col-span-2">
                                  <div className="p-3 bg-[#F6FAFF] rounded-lg">
                                    <img
                                      className="w-8 h-8 rounded object-cover"
                                      src={
                                        product?.image ||
                                        "/img/home/productImg.png"
                                      }
                                      alt="product"
                                    />
                                  </div>

                                  <div>
                                    <div className="text-sm font-bold text-[#222222]">
                                      {product?.description
                                        ?.split("#")[0] // Take only the part before the first "#"
                                        ?.split(" ") // Split by spaces
                                        ?.slice(0, 10) // Take the first 10 words
                                        ?.join(" ") + // Join back into a string
                                        (product?.description
                                          ?.split("#")[0]
                                          ?.split(" ")?.length > 10
                                          ? "..."
                                          : "")}
                                    </div>
                                    <div className="text-sm font-semibold text-[#899CBB]">
                                      {product?.productCode}
                                    </div>
                                  </div>
                                </div>

                                <div className="flex gap-3 items-center">
                                  <div className="text-xs font-extrabold text-[#899CBB]">
                                    {product?.uomCode}
                                  </div>
                                  {userId && (
                                    <div className="border-l text-xs font-extrabold pl-2 text-[#899CBB]">
                                      ${" "}
                                      {/* {product?.specialPrice?.NewPrice
                                        ? (product?.uomMultiplier
                                            ? product?.specialPrice?.NewPrice *
                                              product?.uomMultiplier
                                            : product?.specialPrice?.NewPrice
                                          )?.toFixed(2)
                                        : (product?.uomMultiplier
                                            ? product?.price *
                                              product?.uomMultiplier
                                            : product?.price
                                          )?.toFixed(2)} */}
                                      {(product?.specialPrice?.NewPrice
                                        ? product?.specialPrice?.NewPrice
                                        : product?.uomMultiplier
                                        ? product?.price *
                                          product?.uomMultiplier
                                        : product?.price
                                      )?.toFixed(2)}
                                      {/* {product?.specialPrice?.NewPrice
                                        ? product?.specialPrice?.NewPrice?.toFixed(
                                            2
                                          )
                                        : product?.price} */}
                                    </div>
                                  )}
                                </div>
                                <div className="">
                                  {userId ? (
                                    <>
                                      {product?.quantity === 0 ? (
                                        <Button
                                          size="sm"
                                          onClick={(e) => {
                                            onSaveAddToCart(
                                              e,
                                              product?.productId
                                            );
                                          }}
                                          disabled={
                                            loadingProductId ===
                                            product?.productId
                                          }
                                          loading={
                                            loadingProductId ===
                                            product?.productId
                                          }
                                          className="mt-4 text-xs md:text-sm !w-[120px] rounded-lg font-semibold"
                                        >
                                          Add
                                        </Button>
                                      ) : (
                                        <div className="mt-4 border border-primary !w-[120px] rounded-lg px-2 py-2 flex items-center justify-between">
                                          <button
                                            onClick={(e) =>
                                              handleCartDecrement(e, product)
                                            }
                                            className="p-1 focus:outline-none"
                                            aria-label="Decrement"
                                          >
                                            <FiMinus className="text-primary" />
                                          </button>
                                          <span className="text-xs md:text-sm font-semibold text-[#899CBB]">
                                            {product?.quantity}
                                          </span>
                                          <button
                                            onClick={(e) =>
                                              handleCartIncrement(e, product)
                                            }
                                            className="p-1 focus:outline-none"
                                            aria-label="Increment"
                                          >
                                            <FiPlus className="text-primary" />
                                          </button>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <Button
                                      size="sm"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setSearchTerm("");
                                        setSignInOpen(true);
                                      }}
                                      className="mt-4 text-xs md:text-sm !w-[120px] rounded-lg font-semibold"
                                    >
                                      Add
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </li>
                          )
                        )}
                      </div>

                      <div className="sticky bottom-0 bg-white border-t border-gray-300 p-2 flex justify-center">
                        <Button
                          size="sm"
                          variant="plain"
                          className="text-xs md:text-sm rounded-lg font-semibold"
                          onClick={() => {
                            handleViewAll();
                          }}
                        >
                          View All
                        </Button>
                      </div>
                    </>
                  ) : (
                    <li className="p-2 text-[#222222] text-center text-sm">
                      Sorry! Couldn't Find this product
                    </li>
                  )}
                </ul>
              </>
            )}
          </div>
          <div className="flex items-center gap-4">
            {userLoggedIn ? (
              <Dropdown
                placement="bottom-end"
                title={
                  <div className="w-[38px] h-[38px] rounded-full cursor-pointer bg-primary flex items-center justify-center text-white font-bold text-2xl">
                    {initial}
                  </div>
                }
              >
                <div className="w-[300px] max-h-[360px] min-h-[280px]">
                  <div className="">
                    <div className="px-4 py-2 hover:bg-[#F6FAFF] flex items-center gap-3 cursor-pointer border-b border-primary border-opacity-40">
                      <span className="w-[28px] h-[28px] border border-primary rounded-[4px] flex items-center justify-center">
                        <FaRegUserCircle className="text-primary" />
                      </span>
                      <div className="text-primary font-semibold">
                        {user?.userName}
                      </div>
                    </div>

                    {data?.map((item, index) => (
                      <NavLink
                        key={index}
                        to={item?.link}
                        className="px-4 py-2 hover:bg-[#F6FAFF] flex items-center gap-3"
                      >
                        <Dropdown.Item eventKey="a" className="!h-auto !p-0">
                          <span className="w-[28px] h-[28px] border border-primary rounded-[4px] flex items-center justify-center">
                            {item?.icon}
                          </span>
                          <span className="text-primary text-base font-semibold">
                            {item?.title}
                          </span>
                        </Dropdown.Item>
                      </NavLink>
                    ))}
                  </div>
                  <div className="mx-3 my-2">
                    <Dropdown.Item eventKey="a" className="!h-auto !p-0">
                      {impersonateId ? (
                        <Button
                          className="w-full rounded-lg text-base font-semibold"
                          type="submit"
                          icon={<SignOut />}
                          onClick={handleStopImpersonate}
                        >
                          Exit Impersonation
                        </Button>
                      ) : (
                        <Button
                          className="w-full rounded-lg text-sm font-semibold"
                          type="submit"
                          icon={<SignOut />}
                          onClick={handleSignOut}
                        >
                          Sign out
                        </Button>
                      )}
                    </Dropdown.Item>
                  </div>
                </div>
              </Dropdown>
            ) : (
              <Button
                variant="plain"
                onClick={openSignInDialog}
                className="rounded-lg border-[#474747] text-[#222222] font-semibold"
              >
                Login
              </Button>
            )}
            <div
              onClick={() => {
                setSearchTerm("");
                setDebouncedSearch("");
              }}
            >
              {" "}
              <NavLink
                to={`${
                  userId ? `${CART_PREFIX_PATH}` : `${QUOTE_PREFIX_PATH}`
                }`}
                className="relative"
              >
                <CartIcon
                  color={
                    location?.pathname === "/cart" ||
                    location?.pathname === "/quote"
                      ? "#212d65"
                      : "#899CBB"
                  }
                />
                {userId ? (
                  <>
                    {cartCountUser > 0 && (
                      <span className="absolute -top-2 -right-2 bg-primary text-white text-xs font-semibold rounded-full w-4 h-4 flex items-center justify-center">
                        {cartCountUser}
                      </span>
                    )}
                    {/* {cartTotalPrice > 0 && (
                  <span className="absolute -bottom-6 p-1 rounded bg-primary text-white text-xs font-semibold flex items-center justify-center">
                    <span className="flex">{cartTotalPrice}</span>
                  </span>
                )} */}
                  </>
                ) : (
                  <>
                    {cartCount > 0 && (
                      <span className="absolute -top-2 -right-2 bg-primary text-white text-xs font-semibold rounded-full w-4 h-4 flex items-center justify-center">
                        {cartCount}
                      </span>
                    )}
                  </>
                )}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        width="576px"
        title="Sign out"
        isOpen={isDialogOpen}
        style={{
          content: {
            marginTop: 30,
          },
        }}
        contentClassName="py-0 px-0"
        onClose={closeDialog}
        onRequestClose={closeDialog}
      >
        <div className="py-6 px-6">
          <div className="text-base text-[#222222] font-medium text-center">
            Are you sure you want sign out
          </div>
          {/* <hr className="my-6" /> */}
          <div className="flex items-center justify-between gap-4 mt-6">
            <Button
              variant="plain"
              className="rounded-lg text-sm border-[#899CBB33] border-opacity-20 text-[#899CBB] font-semibold w-full"
              onClick={closeDialog}
            >
              Cancel
            </Button>
            <Button
              size="md"
              className="text-sm rounded-lg font-semibold w-full"
              onClick={() => {
                closeDialog();
                userLoggedIn && signOut();
              }}
            >
              Sign out
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog
        width="576px"
        title="Login"
        isOpen={loginOpen}
        style={{
          content: {
            marginTop: 30,
          },
        }}
        contentClassName="py-0 px-0"
        onClose={closeLoginDialog}
        // onRequestClose={closeLoginDialog}
      >
        <div className="py-6 px-6">
          <div className="mt-2">
            <Radio
              className="mr-4 text-[#222222] text-base font-semibold"
              name="time"
              checked={status === "wholesale"}
              onChange={() => setStatus("wholesale")}
              defaultChecked
            >
              Wholesale Customers
            </Radio>
            <p className="text-sm font-medium text-[#474747] mt-3">
              Are you a wholesale customer? Log in to access your discounted
              pricing and exclusive offers.
            </p>
            <Radio
              className="mr-4 mt-6 text-[#222222] text-base font-semibold"
              name="time"
              checked={status === "retail"}
              onChange={() => setStatus("retail")}
            >
              Retail Customers
            </Radio>
            <p className="text-sm font-medium text-[#474747] mt-3">
              Shopping for yourself? Click here to browse and shop as a retail
              customer no login required. Order online and pick up in-store!
              Same-day pickup is available for orders placed before 2 PM. Please
              allow at least 2 hours for your pickup to be ready.
            </p>

            <Button
              className="text-base w-full rounded-lg font-semibold mt-6"
              onClick={() => {
                if (status === "retail") {
                  closeLoginDialog();
                  navigate(`${ROOT}`);
                  localStorage.setItem("userLoggedIn", "user");
                } else if (status === "wholesale") openSignInDialog();
              }}
            >
              Login
            </Button>
          </div>
        </div>
      </Dialog>

      <Dialog
        // width="576px"
        title={`Welcome to ${process.env.REACT_APP_PROJECT_NAME} Service`}
        isOpen={signInOpen}
        style={{
          content: {
            marginTop: 30,
          },
        }}
        contentClassName="py-0 px-0"
        onClose={closeSignInDialog}
        // onRequestClose={closeSignInDialog}
      >
        <div className="py-6 px-6">
          <Login
            closeSignInDialog={closeSignInDialog}
            openForgotPasswordDialog={openForgotPasswordDialog}
          />
        </div>
      </Dialog>

      <Dialog
        width="576px"
        title="Forgot Password"
        isOpen={forgotPasswordOpen}
        style={{
          content: {
            marginTop: 30,
          },
        }}
        contentClassName="py-0 px-0"
        onClose={closeForgotPasswordDialog}
        // onRequestClose={closeForgotPasswordDialog}
      >
        <div className="py-6 px-6">
          <ForgotPassword
            closeForgotPasswordDialog={closeForgotPasswordDialog}
            openOtpDialog={openOtpDialog}
          />
        </div>
      </Dialog>

      <Dialog
        closable={false}
        width="576px"
        title="Enter OTP"
        isOpen={otpOpen}
        style={{
          content: {
            marginTop: 30,
          },
        }}
        contentClassName="py-0 px-0"
        onClose={closeOtpDialog}
        onRequestClose={closeOtpDialog}
      >
        <div className="py-6 px-6">
          <OTPInput
            closeOtpDialog={closeOtpDialog}
            openNewPasswordDialog={openNewPasswordDialog}
          />
        </div>
      </Dialog>

      <Dialog
        closable={false}
        width="576px"
        title="Create New Password"
        isOpen={newPasswordOpen}
        style={{
          content: {
            marginTop: 30,
          },
        }}
        contentClassName="py-0 px-0"
        onClose={closeNewPasswordDialog}
        onRequestClose={closeNewPasswordDialog}
      >
        <div className="py-6 px-6">
          <NewPassword closeNewPasswordDialog={closeNewPasswordDialog} />
        </div>
      </Dialog>
    </div>
  );
};

export default Header;
