import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  carts: {},
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCart: (state, action) => {
      const { userId, cartItems } = action.payload;
      const key = userId || "guest";
      state.carts[key] = cartItems;
    },

    addToCart: (state, action) => {
      const { userId, productId, quantity } = action.payload;
      const key = userId || "guest";
      state.carts[key] = state.carts[key] || [];

      const existingItemIndex = state.carts[key].findIndex(
        (item) => item.productId === productId
      );

      if (existingItemIndex !== -1) {
        state.carts[key][existingItemIndex].quantity += quantity;
        if (state.carts[key][existingItemIndex].quantity <= 0)
          state.carts[key].splice(existingItemIndex, 1);
      } else {
        if (quantity > 0) state.carts[key].push({ productId, quantity });
      }
    },

    removeFromCart: (state, action) => {
      const { userId, productId } = action.payload;
      const key = userId || "guest";
      state.carts[key] = state.carts[key].filter(
        (item) => item.productId !== productId
      );
    },

    updateQuantity: (state, action) => {
      const { userId, productId, quantity } = action.payload;
      const key = userId || "guest";

      state.carts[key] = state.carts[key].filter(
        (item) => !(item.productId === productId && quantity <= 0)
      );

      state.carts[key] = state.carts[key].map((item) =>
        item.productId === productId ? { ...item, quantity } : item
      );
    },

    removeMultipleFromCart: (state, action) => {
      const { userId, productIds } = action.payload;
      const key = userId || "guest";
      state.carts[key] = state.carts[key].filter(
        (item) => !productIds.includes(item.productId)
      );
    },

    emptyCart: (state, action) => {
      const { userId } = action.payload;
      const key = userId || "guest";
      state.carts[key] = [];
    },
  },
});

export const {
  setCart,
  addToCart,
  removeFromCart,
  updateQuantity,
  emptyCart,
  removeMultipleFromCart,
} = cartSlice.actions;

export default cartSlice.reducer;
