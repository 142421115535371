import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Instagram from "../../svgs/Instagram";
import LinkedIn from "../../svgs/LinkedIn";
import Facebook from "../../svgs/Facebook";
import Phone from "../../svgs/Phone";
import Email from "../../svgs/Email";
// import Visa from "../../svgs/Visa";
// import Paypal from "../../svgs/Paypal";
// import Mastercard from "../../svgs/Mastercard";
import {
  ROOT,
  ABOUT_US_PREFIX_PATH,
  CONTACT_US_PREFIX_PATH,
  FAQS_PREFIX_PATH,
  PRIVACY_POLICY_PREFIX_PATH,
  TERMS_AND_CONDITIONS_PREFIX_PATH,
  RETURN_POLICY_PREFIX_PATH,
  DELIVERY_INFORMATION_PREFIX_PATH,
  TERMS_OF_USE_PREFIX_PATH,
  PRODUCTS_PREFIX_PATH,
} from "../../../constants/route.constant";
import {
  fetchAllFooterSection,
  fetchAllQRCodes,
  getSocialMedia,
} from "../../../services/commonService";
import { capitalizeFirstLetter } from "../../../utils/commonFunctions";

const Footer = () => {
  const [footerData, setFooterData] = useState();
  const [qrCodes, setQRCodes] = useState();
  const [social, setSocial] = useState([]);

  const fetchFooterData = async () => {
    try {
      const resp = await fetchAllFooterSection();
      if (resp?.success) setFooterData(resp?.data);
      else setFooterData();
    } catch (err) {
      console.error("Error fetching footer data", err);
    }
  };

  const fetchAllQRCodesApi = async () => {
    try {
      const resp = await fetchAllQRCodes();
      if (resp?.success) setQRCodes(resp?.data);
      else setQRCodes();
    } catch (err) {
      console.error("Error fetching footer data", err);
    }
  };

  const fetchSocialData = async () => {
    try {
      const resp = await getSocialMedia();
      if (resp?.success) setSocial(resp?.data);
      else setSocial();
    } catch (err) {
      console.error("Error fetching footer data", err);
    }
  };
  useEffect(() => {
    fetchFooterData();
    fetchSocialData();
    fetchAllQRCodesApi(); //eslint-disable-next-line
  }, []);

  return (
    <div className="bg-primary text-base">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-[20px] text-white pt-[40px]">
          <div>
            <img
              src={footerData?.footerMainLogo || "/img/home/whiteLogo.png"}
              alt="logo"
              className="w-[131px] h-[50px]"
            />
            <div className="mt-[24px] font-semibold">
              {capitalizeFirstLetter(footerData?.details) ||
                "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."}
            </div>
            <div className="flex items-center gap-[16px] mt-[16px]">
              {social?.map((item) => {
                const name = item.name.toLowerCase();

                return (
                  <Link
                    key={item.id}
                    to={item.link}
                    target="_blank"
                    className="w-[32px] h-[32px] bg-white bg-opacity-10 flex items-center justify-center rounded-md hover:bg-opacity-25 hover:scale-105 transition duration-300 ease-in-out cursor-pointer"
                  >
                    {name === "instagram" && <Instagram />}
                    {name === "linkedin" && <LinkedIn />}
                    {name === "facebook" && <Facebook />}
                  </Link>
                );
              })}
            </div>

            {/* <div className="flex items-center gap-[16px] mt-[16px]">
              <div className="w-[32px] h-[32px] bg-white bg-opacity-10 flex items-center justify-center rounded-md hover:bg-opacity-25 hover:scale-105 transition duration-300 ease-in-out cursor-pointer">
                <Instagram />
              </div>
              <div className="w-[32px] h-[32px] bg-white bg-opacity-10 flex items-center justify-center rounded-md hover:bg-opacity-25 hover:scale-105 transition duration-300 ease-in-out cursor-pointer">
                <LinkedIn />
              </div>
              <div className="w-[32px] h-[32px] bg-white bg-opacity-10 flex items-center justify-center rounded-md hover:bg-opacity-25 hover:scale-105 transition duration-300 ease-in-out cursor-pointer">
                <Facebook />
              </div>
            </div> */}
          </div>
          <div>
            <div className="text-xl font-bold">Quick Links</div>
            <ul className="font-semibold">
              <li className="mt-[24px] before:content-['•'] before:mr-2">
                <NavLink to={`${ROOT}`}>Home</NavLink>
              </li>
              <li className="mt-[24px] before:content-['•'] before:mr-2">
                <NavLink to={`${ABOUT_US_PREFIX_PATH}`}>About Us</NavLink>
              </li>
              <li className="mt-[24px] before:content-['•'] before:mr-2">
                <NavLink to={`${PRODUCTS_PREFIX_PATH}`}>Products</NavLink>
              </li>
              <li className="mt-[24px] before:content-['•'] before:mr-2">
                <NavLink to={`${CONTACT_US_PREFIX_PATH}`}>Contact Us</NavLink>
              </li>
              <li className="mt-[24px] before:content-['•'] before:mr-2">
                <NavLink to={`${FAQS_PREFIX_PATH}`}> FAQ’s</NavLink>
              </li>
            </ul>
          </div>
          <div>
            <div className="text-xl font-bold">Policies</div>
            <ul className="font-semibold">
              <li className="mt-[24px] before:content-['•'] before:mr-2">
                <NavLink to={`${PRIVACY_POLICY_PREFIX_PATH}`}>
                  Privacy Policy
                </NavLink>
              </li>
              <li className="mt-[24px] before:content-['•'] before:mr-2">
                <NavLink to={`${TERMS_AND_CONDITIONS_PREFIX_PATH}`}>
                  Terms & Conditions
                </NavLink>
              </li>
              <li className="mt-[24px] before:content-['•'] before:mr-2">
                <NavLink to={`${RETURN_POLICY_PREFIX_PATH}`}>
                  Refund/Return Policy
                </NavLink>
              </li>
              <li className="mt-[24px] before:content-['•'] before:mr-2">
                <NavLink to={`${DELIVERY_INFORMATION_PREFIX_PATH}`}>
                  Delivery Information
                </NavLink>
              </li>
              <li className="mt-[24px] before:content-['•'] before:mr-2">
                <NavLink to={`${TERMS_OF_USE_PREFIX_PATH}`}>
                  Term of Use
                </NavLink>
              </li>
            </ul>
          </div>
          <div>
            <div className="flex items-center gap-4">
              <div className="w-[32px] h-[32px] bg-white bg-opacity-10 flex items-center justify-center rounded-md">
                <Phone />
              </div>
              <a href="tel:+6468435115" className="font-semibold">
                {footerData?.phoneNo || "+64 68435115"}
              </a>
            </div>
            <div className="flex items-center gap-4 mt-6">
              <div className="w-[32px] h-[32px] bg-white bg-opacity-10 flex items-center justify-center rounded-md">
                <Email />
              </div>
              <a
                href={`mailto:${footerData?.email || "sales@firstlight.co.nz"}`}
                className="font-semibold"
              >
                {footerData?.email || "sales@firstlight.co.nz"}
              </a>
            </div>
            {qrCodes?.length > 0 && (
              <div className="flex items-center gap-4 mt-6">
                <div>
                  <span className="font-semibold">Android</span>
                  <img
                    className="w-20 h-20 object-cover rounded-lg"
                    src={qrCodes[0]?.android}
                    alt="android"
                  />
                </div>
                <div>
                  <span className="font-semibold">IOS</span>
                  <img
                    className="w-20 h-20 object-cover rounded-lg"
                    src={qrCodes[0]?.ios}
                    alt="ios"
                  />
                </div>
              </div>
            )}
            {/* <div className="text-xl font-bold mt-6">Payment Methods</div>
            <div className="mt-6 flex items-center gap-4">
              <div className="cursor-pointer w-[50px] h-[32px] bg-white bg-opacity-10 flex items-center justify-center rounded-md hover:bg-opacity-25 hover:scale-105 transition duration-300 ease-in-out">
                <Visa />
              </div>
              <div className="cursor-pointer w-[50px] h-[32px] bg-white bg-opacity-10 flex items-center justify-center rounded-md hover:bg-opacity-25 hover:scale-105 transition duration-300 ease-in-out">
                <Paypal />
              </div>
              <div className="cursor-pointer w-[50px] h-[32px] bg-white bg-opacity-10 flex items-center justify-center rounded-md hover:bg-opacity-25 hover:scale-105 transition duration-300 ease-in-out">
                <Mastercard />
              </div>
            </div> */}
          </div>
        </div>
        <div className="mt-[20px] md:mt-[80px] pb-[40px] block lg:grid grid-cols-4">
          <div className="text-white font-semibold md:flex items-center justify-center gap-4 col-span-3">
            {footerData?.rightReservedText || (
              <div>
                © {new Date().getFullYear()}{" "}
                {process.env.REACT_APP_PROJECT_NAME}. All Rights Reserved.
              </div>
            )}

            <div>{footerData?.timing || "Everyday 9:00 AM - 7:30 PM"}</div>
          </div>
          <div className="flex items-center gap-3">
            <a
              href={footerData?.poweredByLink || "https://www.zeabyte.co.nz/"}
              target="_blank"
              rel="noreferrer"
              className="text-white font-semibold cursor-pointer"
            >
              {footerData?.poweredByText ||
                `Powered by ${process.env.REACT_APP_PROJECT_NAME} Ltd`}
            </a>
            <img
              src={footerData?.logo || "/img/home/whiteLogo.png"}
              alt="logo"
              className="!w-20 !h-10"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
